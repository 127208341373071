import { __postAjaxClient } from "../utils/misc/fetchClient";
import {
  getQueryStringValue,
  jsonSafeGet,
  parseCookies,
  setCookie,
} from "./index";
import { CookiesConstants } from "../constants";
import { RegisterDeviceResponse } from "../interfaces";

export const generateRequiredCookiesAndGetToken = async (
  platform
): Promise<RegisterDeviceResponse> => {
  const cookies = parseCookies();
  const authV2Cookies = cookies[CookiesConstants.AUTH_V2];

  let authV2 = authV2Cookies
    ? jsonSafeGet<RegisterDeviceResponse>(authV2Cookies)
    : undefined;
  if (!authV2) {
    const deviceUUID = cookies[CookiesConstants.DEVICE_UUID];
    authV2 = await __postAjaxClient<RegisterDeviceResponse>(
      `/register-device`,
      {
        platform: platform || getQueryStringValue("platform"),
        deviceUUID,
      }
    );

    setCookie(CookiesConstants.AUTH_V2, JSON.stringify(authV2));
    setCookie(CookiesConstants.DEVICE_UUID, authV2.deviceUUID);
    return authV2;
  }

  return authV2;
};
