import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {getQueryStringValue} from "./helpers";
import Auth from "./auth";
import {AuthenticateQueryStringConstants} from "./constants";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const iniAuth = async () => {
    if (window.location.href.includes('/login')) {
        const auth = Auth.getInstance()
        await auth.init(window.location.hostname, undefined, getQueryStringValue(AuthenticateQueryStringConstants.LANGUAGE), getQueryStringValue(AuthenticateQueryStringConstants.COUNTRY), getQueryStringValue(AuthenticateQueryStringConstants.PLATFORM), AuthenticateQueryStringConstants.DEVICE_UUID, parseInt(getQueryStringValue(AuthenticateQueryStringConstants.CITY)))
    }
// todo remove as its used for testing purposes
//     if(window.location.href.includes('localhost')){
//         const newAuth = Auth.getInstance()
//         await newAuth.init("opensooq.com", "login", 'ar', 'jo', 'desktop', '123654',60).then();
//     }
}
iniAuth().then(() => {
    root.render(
        <App/>
    );

})
